@use '../../styles/variables.sass'

.top-to-btm
    position: relative
  
.icon-position
  position: fixed
  bottom: 40px
  right: 25px
  z-index: 20
  
.icon-style
  background: variables.$white
  color: variables.$primary
  border: 0px solid
  border-radius: 50%
  height: 50px
  width: 50px
  cursor: pointer
  animation: movebtn 3s ease-in-out infinite
  transition: all .5s ease-in-out
  
.icon-style:hover
  animation: none
  border: 0px solid
  background-color: variables.$primary
  color: variables.$white