@use './styles/variables.sass'
    
.navbar
    position: fixed
    width: 100%
    z-index: 1000
    
.container
    min-height: 100%

.footer
    margin-top: 100px


@media (max-width: variables.$breakpoint-tablet)   
       


@media (max-width: variables.$breakpoint-phone)
    .footer
        margin-top: 32px

    