@use '../../../../styles/variables.sass'

.btn_primary
    background-color: variables.$primary !important
    border: 1px solid variables.$primary !important
    display: flex !important
    transition-duration: 0.4s !important
    white-space: break-spaces
    &:hover
        background-color: variables.$primary_dark !important
        border: 1px solid variables.$primary_dark !important
    &:active
        background-color: variables.$primary_dark !important
        border: 1px solid variables.$primary_dark !important
    &:disabled
        background-color: variables.$cultured !important
        border: 0px solid variables.$primary_dark !important

    
    