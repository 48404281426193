@use '../../../../styles/variables.sass'

.btn_icon
    color: variables.$primary !important
    background-color: variables.$opacity !important
    border: 1px solid variables.$opacity !important
    padding: 10px 10px
    transition-duration: 0.4s
    display: flex
    align-items: center

    &:hover
        color: variables.$primary !important
        background-color: variables.$cultured !important
        border: 1px solid variables.$opacity !important
        
    &:active
        color: variables.$primary !important
        background-color: variables.$opacity !important
        border: 1px solid variables.$opacity !important

    
    