@use '../../../styles/variables.sass'

.card
    padding: 16px 0px
    background-color: variables.$white
    transition-duration: 0.4s
    color: variables.$dark
    display: flex
    align-items: center
    

    .icon
        width: 40px
        padding-right: 12px

    



    
    