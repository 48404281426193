@use '../../styles/variables.sass'
    
    
.container
    padding-top: 32px
    height: 600px

    .content
        display: flex

    .intro
        margin-top: 16px

    .ilustration
        margin-top: 16px
        width: 100%
        height: 400px
        background-image: url('../../assets/ilustrations/publica_al_bopa.svg')
        background-repeat: no-repeat
        background-size: contain
        background-position: center

    .preguntes
        display: flex
        align-items: center


@media (max-width: variables.$breakpoint-tablet)
    .container

        .content
            display: flex
            flex-direction: column

        .ilustration
            margin-top: 16px
            width: 100%
            height: 400px
            background-image: url('../../assets/ilustrations/publica_al_bopa.svg')
            background-repeat: no-repeat
            background-size: auto
            background-position: center


@media (max-width: variables.$breakpoint-phone)
    .container
        height: 700px