@use '../../styles/variables.sass'

.ant-pagination-item-active a
    background-color: variables.$primary !important
    color: variables.$white !important
    border-color: variables.$white !important
    border-radius: 4px !important

.ant-pagination .ant-pagination-item-active
    border-color: transparent
    &:hover
        border-color: transparent
        
.ant-pagination-item
    background-color: variables.$white
    color: variables.$primary
    border-color: variables.$primary
    border-radius: 4px
    border: 0px

.ant-pagination-prev a,
.ant-pagination-next a
    border-color: variables.$primary
    color: variables.$primary
    &:hover
        background-color: variables.$primary
        border-color: variables.$primary
        color: variables.$white

.ant-table-wrapper .ant-table-row-expand-icon:hover,
.ant-table-wrapper .ant-table-row-expand-icon:focus
    color: variables.$primary

.ant-pagination-disabled a
    background-color: variables.$white
    border-color: variables.$gray
    color: variables.$gray
    &:hover
        background-color: variables.$white
        border-color: variables.$gray
        color: variables.$gray

.ant-table-tbody > tr.ant-table-row:hover > td.ant-table-cell-row-hover
    background-color: variables.$white

.ant-table-wrapper .ant-table-head > tr > td
    padding: 8px

.ant-table-wrapper .ant-table-tbody > tr > td
    padding: 8px
    max-width: 100px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
