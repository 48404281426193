@use '../../styles/variables.sass'

.item 
    margin-top: 0px

.container
    padding-top: 32px

    .caption_regular
        margin-top: 16px

    .content_organism
        margin-top: 8px
    .content_temes
        margin-top: 8px

    .container_btns
        display: flex
        flex-direction: row
        justify-content: space-between
        margin-top: 32px


@media (max-width: variables.$breakpoint-tablet)

@media (max-width: variables.$breakpoint-phone)