@use '../../../styles/variables.sass'

.container
    display: flex
    align-items: center
    flex-direction: row
    justify-content: space-between
    padding: 8px 12px
    transition-duration: 0.4s

    .content
        display: flex
        flex-direction: column

        .title
            color: variables.$dark
            font-weight: 600
            margin-right: 8px
            display: -webkit-box
            -webkit-line-clamp: 1
            -webkit-box-orient: vertical
            overflow: hidden

        .description
            display: -webkit-box
            -webkit-line-clamp: 1
            -webkit-box-orient: vertical
            overflow: hidden


    &:hover
        background-color: variables.$cultured

        
            




    



    
    