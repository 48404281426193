@use '../../../styles/variables.sass'

.container
    .overline_regular
        color: variables.$coral

    .body_regular
        margin-top: 4px


    