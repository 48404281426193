@use '../../styles/variables.sass'

.radios
    margin-top: 12px
    margin-bottom: 24px
    font-family: 'Roboto' !important
    font-size: 8px !important

.form_control
    font-family: variables.$open_sans

    &:active
        border-color: variables.$primary
    &:focus
        border-color: variables.$primary
        box-shadow: 0px 0px 0px 4px rgba(22, 160, 133, 0.5)

.form_control_date
    padding: 0.375rem 0.75rem !important
    font-family: variables.$open_sans !important
    border-radius: 0.375rem !important
    border: 1px solid #dee2e6 !important
    width: inherit !important

.required
    color: red

.content_btn
    margin-top: 24px
    display: flex
    justify-content: flex-end


    