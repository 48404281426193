@use '../../../styles/variables.sass'

.container
    padding: 8px 12px
    transition-duration: 0.4s
    
    .content
        display: flex
        align-items: center
        flex-direction: row
        justify-content: space-between

        .description
            display: -webkit-box
            -webkit-line-clamp: 3
            -webkit-box-orient: vertical
            overflow: hidden
            width: 95%


    &:hover
        background-color: variables.$cultured

        
            




    



    
    