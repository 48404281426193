@use '../../../styles/variables.sass'

.accordionButlleti
    &:hover
        text-decoration: none !important
    &:visited
        text-decoration: underline !important

    .Accordion
        padding: 0
        margin: 0
        --bs-accordion-btn-focus-border-color: #F0FCF9
        --bs-accordion-btn-focus-box-shadow: 0px 0px 0px 4px rgba(22, 160, 133, 0.5) !important
        --bs-accordion-border-color: 0px
        &:hover
            text-decoration: none !important
            border: 1px solid green

    .accordion-header
        border-radius: 8px
        transition-duration: 0.4s
        &:hover
            box-shadow: 0px 4px 14px rgba(32, 31, 31, 0.15)
    // .AccordionButlletin_container__PIll8 
    //     padding: 0px
    //     background-color: #FFF
    //     color: #222
    //     border-radius: 0px
    //     border: 1px solid #222
    //     &:hover
    //         border-radius: 10px
    //         text-decoration: none !important

    .accordion-button:not(.collapsed)
        background-color: variables.$primary
        color: variables.$white
        border: variables.$white !important
        &:hover
            text-decoration: none !important
        &:focus
            box-shadow: 0px 0px 0px 4px rgba(22, 160, 133, 0.5) !important

        &:active
            box-shadow: 0px 0px 0px 4px rgba(22, 160, 133, 0.5) !important
        
        &:visited
            box-shadow: 0px 0px 0px 4px rgba(22, 160, 133, 0.5) !important


    .accordion-button:not(.collapsed) 
        &:after
        color: variables.$dark

    .accordion-button:not(.collapsed)::after
        background-image: var(--bs-accordion-btn-icon)
        &:hover
            text-decoration: none !important

    .accordion-button 
        margin: 0px
        padding: 0px
        &:hover
           text-decoration: none !important
        &:focus
            box-shadow: 0px 0px 0px 4px rgba(22, 160, 133, 0.5) !important
        &:active
            box-shadow: 0px 0px 0px 4px rgba(22, 160, 133, 0.5) !important
        &:visited
            box-shadow: 0px 0px 0px 4px rgba(22, 160, 133, 0.5) !important

    .accordion-item
        border: none
        width: 100%
        &:hover
            text-decoration: none !important

    .accordion-item :last-of-type .accordion-button.collapsed
        border: 1px solid variables.$cultured
        border-radius: variables.$border_radius_sm
        &:hover
            text-decoration: none !important

    .accordion-item:first-of-type .accordion-button:not(.collapsed)
        background-color: variables.$primary_light
        border: 0px
        border-radius: 8px
        box-shadow: none
        &:focus
            box-shadow: 0px 0px 0px 4px rgba(22, 160, 133, 0.5)
        &:active
            box-shadow: 0px 0px 0px 4px rgba(22, 160, 133, 0.5)
        &:visited
            box-shadow: 0px 0px 0px 4px rgba(22, 160, 133, 0.5)


    .accordion-button::after
        display: none
        margin-right: 8px
        color: variables.$primary    