@use '../../../styles/variables.sass'

.container
    text-decoration: none !important
    text-color: black
    display: flex
    flex-direction: row
    justify-content: flex-start
    border-radius: variables.$border_radius_sm
    border: 1px solid variables.$cultured
    transition-duration: 0.4s
    overflow-x: hidden
    &:hover
        box-shadow: 0px 4px 14px rgba(32, 31, 31, 0.15)

    .content_sumari
        padding: 12px 16px
        display: flex
        flex-direction: column
        justify-content: space-between
        .sumari
            margin-bottom: 8px
            display: -webkit-box
            -webkit-line-clamp: 3 !important
            -webkit-box-orient: vertical !important
            overflow: hidden !important
            text-overflow: ellipsis !important
            color: black !important
        .sumari_visited
            margin-bottom: 8px
            display: -webkit-box
            -webkit-line-clamp: 3 !important
            -webkit-box-orient: vertical !important
            overflow: hidden !important
            text-overflow: ellipsis !important
            color: variables.$primary !important
        .badge
            display: flex
            .badge_container
                cursor: default
                box-sizing: border-box
                align-items: center
                font-family: 'Roboto'
                font-style: normal
                font-weight: 400
                font-size: 14px
                line-height: 16px
                letter-spacing: 0.01em
                color: #000000
                padding: 4px 8px
                background: #FFFFFF
                border: 1px solid #819CA9
                border-radius: 4px
                white-space: nowrap !important
                overflow: hidden !important
                text-overflow: ellipsis !important
                margin-right: 0.5rem

    .content_counter
        background-color: variables.$primary_light
        padding: 12px 16px
        border-radius: 7px 0 0 7px
        text-align: center
        display: inline-grid
        align-items: center
        justify-items: center
        align-content: center
        .overline_medium
            color: variables.$primary
            margin-bottom: 8px
        .overline_regular
            margin-bottom: 8px
            color: black

    .content_arrow
        display: none
        // max-width: 10%
        // padding: 16px 8px

@media (max-width: variables.$breakpoint-tablet)

    .container
        display: flex
        flex-direction: row
        justify-content: flex-start
        border-radius: variables.$border_radius_sm
        border: 1px solid variables.$cultured
        transition-duration: 0.4s
        max-width: 100%
        overflow-x: hidden
        &:hover
            box-shadow: 0px 4px 14px rgba(32, 31, 31, 0.15)

        .content_sumari
            padding: 12px 16px
            display: flex
            flex-direction: column
            align-content: space-between
            max-width: 80%
            .sumari
                font-size: 15px
                margin-bottom: 8px
                display: -webkit-box
                -webkit-line-clamp: 4 !important
                -webkit-box-orient: vertical !important
                overflow: hidden !important
                text-overflow: ellipsis !important
            .sumari_visited
                font-size: 15px
                margin-bottom: 8px
                display: -webkit-box
                -webkit-line-clamp: 4 !important
                -webkit-box-orient: vertical !important
                overflow: hidden !important
                text-overflow: ellipsis !important
                color: variables.$primary !important
            .badge
                display: flex
                .badge_container
                    box-sizing: border-box
                    align-items: center
                    font-family: 'Roboto'
                    font-style: normal
                    font-weight: 400
                    font-size: 14px
                    line-height: 16px
                    letter-spacing: 0.01em
                    color: #000000
                    padding: 4px 8px
                    background: #FFFFFF
                    border: 1px solid #819CA9
                    border-radius: 4px
                    white-space: nowrap !important
                    overflow: hidden !important
                    text-overflow: ellipsis !important
                    max-width: 30%
                    margin-right: 0.5rem

        .content_counter
            max-width: 30%
            background-color: variables.$primary_light
            padding: 12px 16px
            border-radius: 7px 0 0 7px
            text-align: center
            display: inline-grid
            align-items: center
            justify-items: center
            align-content: center
            .overline_medium
                color: variables.$primary
                margin-bottom: 8px
            .overline_regular
                margin-bottom: 8px

        .content_arrow
            display: none