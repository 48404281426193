@use '../../../../styles/variables.sass'

#accordionFilters
    #accordionFilters2Level
        .AccordionTitleLevel2
                display: flex
                flex-direction: row
                justify-content: space-between
                align-items: center
                margin-top: -10px
                margin-bottom: -10px
        .accordion-button:not(.collapsed)::after
            background-image: none
            width: 0rem !important             
        .accordion-button.collapsed::after
            background-image: none
            width: 0rem !important

        .accordion-item:first-of-type 
        .accordion-button:not(.collapsed)
            font-size: 10px !important
            font-weight: 600
            padding: 8px !important
            border: 0px !important
            box-shadow: none
            &:hover
                background-color: #E8ECEF

        .container_filtres_accordion_primary 
        .accordion-button
            display: inline
        .collapsed
            font-weight: 400
            border: none
            padding: 8px !important
            border: 0px !important
            font-size: 10px !important
            box-shadow: none
            &:hover
                background-color: #E8ECEF !important

    
    
    





    



    
    