@use '../../styles/variables.sass'
    
.container
    padding-top: 32px
    height: max-content

    .section
        margin-top: 32px

@media (max-width: variables.$breakpoint-tablet)
    .container

@media (max-width: variables.$breakpoint-phone)
   