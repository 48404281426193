@use '../../../../styles/variables.sass'

.container
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    img
        width: 200px


    .overline_regular
        margin-top: 16px
        text-align: center



    



    
    