@use '../../../styles/variables.sass'

.my-modal
    width: 440px
.login_icon
    margin-right: 8px
.text_login
    -webkit-flex-grow: 1
    flex-grow: 1
    font-family: "Google Sans",arial,sans-serif
    font-weight: 500
    overflow: hidden
    text-overflow: ellipsis
    vertical-align: top
.iconTextLogin
    display: -webkit-box
    display: -webkit-flex
    display: flex
    -webkit-align-items: center
    align-items: center
    -webkit-flex-direction: row
    flex-direction: row
    justify-content: flex-start
    -webkit-flex-wrap: nowrap
    flex-wrap: nowrap
    height: 100%
    position: relative
    width: 100%

.dropdown-toggle
    display: inline
    color: variables.$dark !important
    background-color: variables.$white !important
    border-style: none !important
    &:hover
        color: variables.$dark !important
        background-color: variables.$culture !important
        
    &:active
        color: variables.$dark !important
        background-color: variables.$cultured !important
    
    &:focus
        color: variables.$dark !important
        background-color: variables.$cultured !important

    &:not(:active)
        color: variables.$dark !important
        background-color: variables.$white !important


.dropdown-item
    border-radius: 0px !important
    &:active
        color: variables.$dark !important
        background-color: variables.$cultured !important
    &:focus
        color: variables.$dark !important
        background-color: variables.$cultured !important

#signIn
    .dropdown-toggle
        display: inline
        color: variables.$white !important
        border-style: none
        box-shadow: none
        &:hover
            color: variables.$white !important
            background-color: variables.$primary-dark !important
        &:active
            color: variables.$white !important
            background-color: variables.$primary-dark !important
        &:focus
            color: variables.$white !important
            background-color: variables.$primary-dark !important


@media (max-width: variables.$breakpoint-tablet)




