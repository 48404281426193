@use '../../styles/variables.sass'
    
.container
    padding-top: 32px

    .section
        margin-top: 32px

    .body_regular
        margin-top: 16px
    

    


@media (max-width: variables.$breakpoint-tablet)
    .container



@media (max-width: variables.$breakpoint-phone)
   