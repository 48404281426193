@use '../../../../styles/variables.sass'

.btn_link2
    text-decoration: none !important

.btn_link
    color: variables.$primary !important
    background-color: variables.$opacity !important
    border: 1px solid variables.$opacity !important
    display: flex !important
    transition-duration: 0.4s !important
    &:hover
        color: variables.$primary !important
        background-color: variables.$opacity !important
        border: 1px solid variables.$opacity !important
        text-decoration: underline
        
    &:active
        color: variables.$primary !important
        background-color: variables.$opacity !important
        border: 1px solid variables.$opacity !important
    