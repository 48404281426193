@use '../../styles/variables.sass'

.containerSearch
    display: flex !important

.formcalendar
    display: flex !important
    margin-left: 15px
    justify-content: center
    .form_calendar_item
        margin: 15px 15px 15px 0px
        width: fit-content

.form_control
    font-family: variables.$open_sans

    &:active
        border-color: variables.$primary
    &:focus
        border-color: variables.$primary
        box-shadow: 0px 0px 0px 4px rgba(22, 160, 133, 0.5)

.required
    color: red

.content_btn
    margin-top: 24px
    display: flex
    justify-content: flex-end

    
.container_banner
    background-image: url('../../assets/images/banner_desktop.svg')
    background-repeat: no-repeat
    width: 100%
    height: 400px
    background-size: cover
    background-position: bottom
    padding-top: 80px
    background-color: #FAFCFE
    .content_banner
        max-width: 800px
        margin: 0 auto
        text-align: center
        display: flex
        flex-direction: column
        padding: 0 24px
        .title
            font-size: 2rem
            margin-bottom: 24px
            font-weight: 600

.content_buttons
    display: flex
    flex-direction: row
    justify-content: flex-end

.item_btn_primary
    margin-left: 16px

.container_main_cerca,
.container_main
    margin-top: 48px
    margin-bottom: 48px
    margin-right: 20px

    .container_cards_bopa
        margin-top: 16px
        display: flex
        flex-direction: column

        .content_cards_bopa
            min-width: 30%
            margin-right: 12px
            margin-bottom: 12px

.container_personal_items
    display: flex !important
    flex-direction: row
    margin-top: 40px
    justify-content: space-between

    .content_personal_alerts
        width: 32%
    .content_personal_cerques
        width: 32%
    .content_personal_favorits
        width: 32%

.container_secondary
    margin-top: 48px
    margin-bottom: 48px

    .container_ajuda
        margin-top: 32px

    .container_comentaris
        margin-top: 32px


@media (max-width: variables.$breakpoint-tablet)
    .container_banner
        background-image: url('../../assets/images/banner_tablet.svg')


@media (max-width: variables.$breakpoint-phone)
    .container_banner
        background-image: url('../../assets/images/banner_phone.svg')
    // .container_main_cerca
    //     display: none

    .containerSearch
        display: flex !important
        flex-direction: column

    .container_main
        margin-bottom: 0px

        .container_cards_bopa
            .content_cards_bopa
                min-width: 100%

    .container_personal_items
        display: flex
        flex-direction: column

        .content_personal_alerts
            width: 100%

        .content_personal_cerques
            width: 100%
            margin-top: 32px

        .content_personal_favorits
            width: 100%
            margin-top: 32px