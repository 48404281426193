@use '../../../../styles/variables.sass'

.btn_icon
    color: variables.$red !important
    background-color: variables.$opacity !important
    border: 1px solid variables.$opacity !important
    padding: 8px 8px
    transition-duration: 0.4s
    display: flex
    align-items: center


    &:hover
        color: variables.$red !important
        background-color: variables.$red_light !important
        border: 1px solid variables.$red_light !important
        
    &:active
        color: variables.$red !important
        background-color: variables.$red_light !important
        border: 1px solid variables.$red_light !important

