@use './styles/variables.sass'

// .frame-2
//     display: none

// .frame-1
//     display: none
    
.invisible
    display: none
    

.form-check-input:checked
    font-family: variables.$open_sans
    background-color: variables.$primary !important
    border-color: variables.$primary !important

.form-check-input
    font-family: variables.$open_sans !important
    border-color: variables.$pewter !important
    &:focus
        border-color: variables.$primary !important
        box-shadow: 0px 0px 0px 4px rgba(22, 160, 133, 0.5) !important

.form-check-input:checked[type=checkbox]
    background-color: variables.$primary !important
    border-color: variables.$primary !important

.form-check-input:active[type=checkbox]::before
    background-color: variables.$primary !important
    border-color: variables.$primary !important
    background-color: variables.$primary  !important

.form-check-input:focus[type=checkbox]
    border-color: variables.$primary !important
    box-shadow: 0px 0px 0px 4px rgba(22, 160, 133, 0.5) !important
.form-check-input:checked
    background-color: variables.$primary !important
    border-color: variables.$primary !important

.form-check-input
    font-family: variables.$open_sans !important
    border-color: variables.$pewter !important
    &:focus
        border-color: variables.$primary !important
        box-shadow: 0px 0px 0px 4px rgba(22, 160, 133, 0.5) !important

.form-select
    font-family: variables.$open_sans !important
    &:active
        border-color: variables.$primary !important
    &:focus
        border-color: variables.$primary !important
        box-shadow: 0px 0px 0px 4px rgba(22, 160, 133, 0.5) !important

.form-control
    font-family: variables.$open_sans
    &:active
        border-color: variables.$primary !important
    &:focus
        border-color: variables.$primary !important
        box-shadow: 0px 0px 0px 4px rgba(22, 160, 133, 0.5) !important

.form-check-input:checked[type=checkbox]
    background-color: variables.$primary !important
    border-color: variables.$primary !important

.form-check-input:active[type=checkbox]::before
    background-color: variables.$primary !important
    border-color: variables.$primary !important
    background-color: variables.$primary  !important

.form-check-input:focus[type=checkbox]
    border-color: variables.$primary !important
    box-shadow: 0px 0px 0px 4px rgba(22, 160, 133, 0.5) !important

.app-spinner
    display: flex
    justify-content: center
    vertical-align: middle
    img 
        margin: 0 auto

.btn-close
    --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(22, 160, 133, 0.5) !important

.ant-radio-wrapper span.ant-radio+*
    font-size: 14px !important