@use '../../../styles/variables.sass'

.container
    box-sizing: border-box
    display: flex
    flex-direction: row
    align-items: center
    padding: 4px 8px
    gap: 6px
    height: 24px
    background: #FFFFFF
    border: 1px solid #819CA9
    border-radius: 4px
    &:hover
        background: variables.$cultured

.containerContent
    height: 16px
    font-family: 'Roboto'
    font-style: normal
    font-weight: 400
    font-size: 14px
    line-height: 16px
    letter-spacing: 0.01em
    color: #000000
    flex: none
    order: 0
    flex-grow: 0

.deleteButton    
    vertical-align: middle