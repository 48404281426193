@use '../../../styles/variables.sass'

.btn_link
    text-decoration: none !important
    
.card
    box-sizing: border-box
    display: flex
    flex-direction: row
    align-items: center
    padding: 4px 8px
    gap: 8px
    height: 30px
    left: 20px
    top: 20px
    background: #FFFFFF
    border: 1px solid #E8ECEF
    border-radius: 4px
    color: variables.$dark
    .caption_regular
        display: -webkit-box
        -webkit-line-clamp: 2
        -webkit-box-orient: vertical
        overflow: hidden
    &:hover
        border: 1px solid variables.$cultured
        box-shadow: 0px 4px 14px rgba(32, 31, 31, 0.15)

    .icon
        width: 16px
        height: 16px

    



    
    