@use '../../styles/variables.sass'
    
.container
    padding-top: 16px

    .breadcrumb_active
        -webkit-line-clamp: 1
        -webkit-box-orient: vertical
        overflow: hidden
        width: 30%
        white-space: nowrap
        text-overflow: ellipsis

    .container_main
       
    .content_tags
        margin-top: 8px
        display: flex
        flex-direction: row
        justify-content: space-between

    .tags
        display: flex
        flex-direction: row

    .body_medium
        padding: 12px 16px
        background-color: variables.$culture
        margin-top: 16px
        border-radius: variables.$border_radius_sm

    .content_export
        margin-top: 16px
        display: flex
        flex-direction: row

    .container_annex
        margin-top: 24px

    .content_annex
        padding-top: 12px
        display: flex
        flex-direction: row
        flex-wrap: wrap

        .content_card
            margin-right: 12px
            margin-bottom: 12px

    .content_search
        margin-top: 32px

    .body_regular
        margin-top: 24px

    .content_btn_tornar
        margin-top: 32px

    .content_btns
        margin-top: 24px
        display: flex
        justify-content: center 


@media (max-width: variables.$breakpoint-tablet)

@media (max-width: variables.$breakpoint-phone)
    .container_altres_docs
        margin-top: 32px
        margin-bottom: 48px