@use '../../styles/variables.sass'
    
#accordionFiltersAlertes
    .accordion-item.collapsed
        border:0px
    
    .accordion-item:not(.collapsed)
        border: 10px

    .accordion-body
        padding: 20px 20px !important 
        border-right: 1px solid variables.$gray
        border-left: 1px solid variables.$gray

    .accordion-button.collapsed
        color: black
        border: 10px
        background-color: #FFF !important
        border: 1px solid variables.$gray
        font-weight: 400 
        padding: 15px
        border-radius: 0px !important
        box-shadow: 0px 0px 0px 0px rgba(22, 160, 133, 0.5)
        &:hover
            background-color: #F0FCF9 !important

    .accordion-button:not(.collapsed)
        color: black
        border: 10px
        background-color: #FFF !important
        border: 1px solid variables.$gray
        font-weight: 600 
        padding: 15px
        border-radius: 0px !important
        box-shadow: 0px 0px 0px 0px rgba(22, 160, 133, 0.5)
        background-color: #F0FCF9 !important
        &:hover
            background-color: #F0FCF9 !important

    .accordion-button:not(.collapsed)::after
        background-image: var(--bs-accordion-btn-icon)

    label
        font-size: 0.8rem

    .accordion-body
        padding: 8px 8px  
    