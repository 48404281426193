@use '../../styles/variables.sass'

#htmlContent
    .table, th, td 
        border: 1px solid black
        border-collapse: collapse
        padding: 2px 10px 2px 10px
    img
        display: none

.highlight
  background-color: yellow

.container
    a
        text-decoration: none
        color: variables.$primary
        &:hover
            text-decoration: underline

#docsItems
    .dropdown-toggle:after
        display: none
    .dropdown-toggle
        padding: 0px !important
        display: inline
        color: variables.$white !important
        border-style: none
        box-shadow: none
        display: inline
        color: variables.$dark !important
        background-color: variables.$white
        border-style: none
        &:hover
            padding: 0px !important
            color: variables.$white !important
        &:active
            color: variables.$dark !important
            background-color: variables.$cultured !important
        
        &:focus
            color: variables.$dark !important
            background-color: variables.$cultured !important

        &:not(:active)
            color: variables.$primary
            background-color: variables.$white


    .dropdown-item
        text-decoration: none !important
        border-radius: 0px !important
        &:active
            text-decoration: none !important
            color: variables.$dark !important
            background-color: variables.$cultured !important
        &:focus
            text-decoration: none !important
            color: variables.$dark !important
            background-color: variables.$cultured !important


@media print 
  body
    display: none
  
  #contenido 
    display: block

  #contenido h1 
    font-size: 24px
    color: #FF0000
