@use '../../../styles/variables.sass'

.container
    box-sizing: border-box
    display: flex
    flex-direction: row
    align-items: center
    font-family: 'Roboto'
    font-style: normal
    font-weight: 400
    font-size: 14px
    line-height: 16px
    letter-spacing: 0.01em
    color: #000000
    padding: 4px 8px
    background: #FFFFFF
    border: 1px solid #819CA9
    border-radius: 4px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    margin-right: 0.5rem

.deleteButton    
    vertical-align: middle