@use '../../../styles/variables.sass'
        
.counter
    text-decoration: none !important
    background-color: variables.$primary
    padding: 8px
    font-size: 1.25rem
    font-weight: 600
    color: variables.$white
    border-radius: 7px 0px 0px 7px
    min-width: 60px
    text-align-last: center
    &:hover
        text-decoration: none !important

.counter_extra
    text-decoration: none !important
    background-color: variables.$primary_light
    padding: 8px
    font-size: 1.25rem
    font-weight: 600
    color: variables.$dark
    border-radius: 7px 0px 0px 7px
    min-width: 60px
    text-align-last: center
    &:hover
        text-decoration: none !important
    .counter_extra_title
        font-size: 0.75rem
        font-weight: 600
        color: variables.$primary


.title
    text-decoration: none !important
    margin-left: 12px
    border-radius: 8px
    &:hover
        text-decoration: none !important

.title_visited
    color: variables.$primary
    text-decoration: none !important
    margin-left: 12px
    border-radius: 8px
    &:hover
        text-decoration: none !important

.flag_extra
    text-decoration: none
    margin-left: 16px
    margin-right: 12px

.content_btns
    text-decoration: none
    margin-top: 24px
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center

.buttonsContent
    text-decoration: none
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    margin-right: 10px

.accordion_content
    text-decoration: none
    display: flex
    justify-content: space-between
    align-content: space-between
    flex-direction: row
    align-items: center
    flex-wrap: wrap
    width: inherit

@media (max-width: variables.$breakpoint-phone)
    .accordion_content
        display: flex
        align-items: flex-start
        flex-direction: column
        width: inherit
    .buttonsContent
        display: flex
        flex-direction: row
        align-items: center
        justify-content: center
        margin-right: 10px
        margin-bottom: 7px
    .title
        margin: 7px
        border-radius: 8px
    .counter_extra
        background-color: variables.$primary_light
        padding: 24px
        font-size: 1.25rem
        font-weight: 600
        color: variables.$dark
        border-radius: 7px 0px 0px 7px
        min-width: 60px
        text-align-last: center
        margin-right: 20px