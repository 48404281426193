@use '../../../styles/variables.sass'
    
.container
    width: 100%
    margin: auto 0
    

    .options_menu
        background-color: variables.$primary
        padding: 24px 0px
        display: flex
        justify-content: space-between

    a
        text-decoration: none
        color: variables.$white
        margin-right: 24px
        font-size: 0.9rem
        
    a:hover
        text-decoration: underline

    .list 
        display: flex
        list-style: none
        flex-wrap: wrap

    ul 
        margin: 0



    .copywrite
        width: 100%
        background-color: variables.$dark
        padding: 8px 24px
        color: variables.$white
        display: flex

        span
            font-size: 0.7rem
            margin: 0 auto

            b
                font-size: 0.7rem

            
    
@media (max-width: variables.$breakpoint-tablet)
    .container
        .options_menu
            flex-direction: column
            justify-content: center

            .item
                width: 100%
                margin-top: 12px
                
            .item:first-child
                    margin-top: 0

            ul
                padding-left: 16px

            .options_menu_right
                margin-top: 12px
                

            


