@use '../../../styles/variables.sass'

.btn_link
    text-decoration: none !important

.card
    padding: 12px
    background-color: variables.$white
    border-radius: variables.$border_radius_sm
    border: 1px solid variables.$cultured
    transition-duration: 0.4s
    color: variables.$dark
    display: flex
    align-items: center
    cursor: pointer

    .caption_regular
        display: -webkit-box
        -webkit-line-clamp: 2
        -webkit-box-orient: vertical
        overflow: hidden

    &:hover
        border: 1px solid variables.$cultured
        box-shadow: 0px 4px 14px rgba(32, 31, 31, 0.15)

    .icon
        width: 40px
        padding-right: 12px

    



    
    