@use '../../styles/variables.sass'
    
.container
    padding-top: 32px

    .intro
        margin-top: 16px

    .preguntes
        display: flex
        align-items: center

    .body_medium
        margin-top: 24px

    .content_form
        margin-top: 24px


@media (max-width: variables.$breakpoint-tablet)
    .container

        .preguntes
            display: flex
            flex-direction: column
            justify-content: flex-start
            text-align: left
            align-items: start



@media (max-width: variables.$breakpoint-phone)
    