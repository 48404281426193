@use '../../../styles/variables.sass'

.logotipo
    height: 60px


.linkIcon
    margin-left: 2px
    display: contents

.navbar 
    display: flex
    justify-content: space-between
    background-color: variables.$white
    padding: 1rem
    align-items: center
    width: 100%
    box-shadow: 0px 2px 4px rgba(32, 31, 31, 0.075)

    ul 
        margin: 0
        li:last-child
            margin-right: 0

.list 
    display: flex
    list-style: none
    align-items: center

.itemBtn 
    display: flex
    margin-left: 24px
    .item 
        margin-right: 1rem

.loginItem
    font-family: 'Open Sans'
    font-style: normal
    font-weight: 400
    font-size: 16px
    line-height: 22px
    letter-spacing: 0.02em
    text-decoration: none
    padding: 0.5rem 1rem
    color: variables.$coral
    border-radius: variables.$border_radius_sm
    font-size: 0.9rem
    font-weight: 500

.item
    font-family: 'Open Sans'
    font-style: normal
    font-weight: 400
    font-size: 16px
    line-height: 22px
    letter-spacing: 0.02em
    text-decoration: none
    padding: 0.5rem 1rem
    color: variables.$coral
    border-radius: variables.$border_radius_sm
    font-size: 0.9rem
    font-weight: 500
    &:hover
        background-color: variables.$primary_light
        
.itemActive
    font-family: 'Open Sans'
    font-style: normal
    font-weight: 400
    font-size: 16px
    line-height: 22px
    letter-spacing: 0.02em
    text-decoration: none
    padding: 0.5rem 1rem
    color: variables.$primary
    border-radius: variables.$border_radius_sm
    font-weight: 600
    font-size: 16px
    &:hover
        background-color: variables.$primary_light

.itemBtnMenu
    display: none
.loginItemMobile
    display: none

.dropdownSignIn
    background-color: variables.$primary !important
    border: 1px solid variables.$primary !important
    &:hover
        background-color: variables.$primary_dark !important
        border: 1px solid variables.$primary_dark !important
    &:active
        background-color: variables.$primary_dark !important
        border: 1px solid variables.$primary_dark !important


@media (max-width: variables.$breakpoint-tablet)
    .logotipo
        height: 25px
    .item 
        display: none
    .itemActive
        display: none
    .loginItem
        display: none

    .loginItemMobile
        // font-family: 'Open Sans'
        // font-style: normal
        // font-weight: 400
        // font-size: 16px
        // line-height: 22px
        // letter-spacing: 0.02em
        // text-decoration: none
        padding: 0.5rem 0.2rem
        // color: variables.$coral
        border-radius: variables.$border_radius_sm
        // font-size: 0.9rem
        // font-weight: 500
        display: block
    .itemBtnMenu
        // font-family: 'Open Sans'
        // font-style: normal
        // font-weight: 400
        // font-size: 16px
        // line-height: 22px
        // letter-spacing: 0.02em
        // text-decoration: none
        padding: 0.5rem 0.2rem
        // color: variables.$coral
        border-radius: variables.$border_radius_sm
        // font-size: 0.9rem
        // font-weight: 500
        display: block




