@use '../../../../styles/variables.sass'

.btn_icon
    color: variables.$primary !important
    background-color: variables.$white !important
    border: 1px solid variables.$primary !important
    padding: 8px 8px
    transition-duration: 0.4s
    display: flex
    align-items: center


    &:hover
        color: variables.$primary !important
        background-color: variables.$white !important
        border: 1px solid variables.$primary !important
        box-shadow: 0px 4px 14px rgba(32, 31, 31, 0.15)
        
    &:active
        color: variables.$primary !important
        background-color: variables.$opacity !important
        border: 1px solid variables.$opacity !important

.select
    color: variables.$white !important
    background-color: variables.$primary !important
    border: 1px solid variables.$primary !important
    padding: 8px 8px
    transition-duration: 0.4s
    display: flex
    align-items: center

    &:hover
        color: variables.$white !important
        background-color: variables.$primary !important
        border: 1px solid variables.$primary !important
        box-shadow: 0px 4px 14px rgba(32, 31, 31, 0.15)
        
    &:active
        color: variables.$white !important
        background-color: variables.$primary !important
        border: 1px solid variables.$primary !important