@use '../../../../styles/variables.sass'

.btn_secondary
    border: 1.8px solid variables.$primary !important
    color: variables.$primary !important
    display: flex
    transition-duration: 0.4s
    &:hover
        background-color: variables.$primary_light !important
        color: variables.$primary !important
        border: 1.8px solid variables.$primary !important
    &:active
        background-color: variables.$primary_light !important
        color: variables.$primary !important
        border: 1.8px solid variables.$primary !important
    &:disabled
        color: variables.$white !important
        background-color: variables.$cultured !important
        border: 0px solid variables.$primary_dark !important

    .iconRight
        svg
            margin-left: 8px

    .iconLeft
        svg
            margin-right: 8px