@use '../../../styles/variables.sass'

.lable_medium
    padding: 4px 12px
    background-color: variables.$primary
    color: variables.$white
    border-radius: variables.$border_radius_lg
    border: 0px solid variables.$white



    



    
    