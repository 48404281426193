@use '../../../../styles/variables.sass'

.btn_login
    -webkit-border-radius: 4px
    border-radius: 4px
    -webkit-box-sizing: border-box
    box-sizing: border-box
    -webkit-transition: background-color .218s,border-color .218s
    transition: background-color .218s,border-color .218s
    -webkit-user-select: none
    -webkit-appearance: none
    background-color: #fff !important
    background-image: none !important
    border: 1px solid #dadce0 !important
    color: #3c4043 !important
    cursor: pointer
    font-family: "Google Sans",arial,sans-serif
    font-size: 14px
    height: 40px
    letter-spacing: 0.25px
    outline: none
    overflow: hidden
    padding: 0 12px
    position: relative
    text-align: center
    vertical-align: middle
    white-space: nowrap
    background-color: variables.$white !important
    color: black
    border: 0.5px solid #dee2e6 !important
    border-radius: 0.25rem
    display: block
    width: 400px
    max-width: 400px
    min-width: min-content
    padding: 2px 10px
    &:hover
        background-color: variables.$primary_light !important
        color: variables.$primary !important
        border: 1.8px solid variables.$primary !important
    &:active
        background-color: variables.$primary_light !important
        color: variables.$primary !important
        border: 1.8px solid variables.$primary !important
    &:focus
        background-color: variables.$primary_light !important
        color: variables.$primary !important
        border: 1.8px solid variables.$primary !important
    &:disabled
        color: variables.$white !important
        background-color: variables.$cultured !important
        border: 0px solid variables.$primary_dark !important
        
    // &:hover
    //     background-color: rgba(66,133,244,.04)
    //     color: black
    //     border: 1px solid #d2e3fc
    // &:active
    //     background-color: rgba(66,133,244,.04)
    //     color: black
    //     border: 1px solid #d2e3fc
    // &:focus
    //     background-color: rgba(66,133,244,.04)
    //     color: black
    //     border: 1px solid #d2e3fc
    // &:disabled
    //     border: 1px solid #999999
    //     background-color: #cccccc
    //     color: #666666

    
    