@use '../../styles/variables.sass'

.header
    display: flex
    align-items: center
    flex-direction: row
    justify-content: space-between
    padding: 12px 0

    .title
        display: flex
        align-items: center

        .icon
            margin-right: 8px

.body
    width: 100%
    height: 300px
    border: 1px solid variables.$cultured
    border-radius: variables.$border_radius_sm
    padding: 4px 0
    overflow-y: scroll
