@use '../../styles/variables.sass'

.container
    padding-top: 32px

    .container_filtres
        margin-top: 24px

        .container_btn_filtres
            margin-bottom: 16px
            width: 100%

            button:not(:disabled)
                width: 100%
                justify-content: center

        .content_filtres_form
            margin-top: 16px

    .content_filtres_header
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: center

        .content_filtres_header_btn
            display: flex
            flex-direction: row
            justify-content: end

    .content_filtres_footer
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: center
        margin-top: 24px
        

        .content_filtres_footer_btn
            display: flex
            flex-direction: row
            justify-content: end

    .container_main
        margin-top: 16px

        .content_header
            display: flex
            flex-direction: row
            justify-content: space-between
            align-items: center

        .content_badge
            padding: 12px
            margin-top:16px
            background-color: variables.$culture
            border-radius: variables.$border_radius_sm

            .content_badge_main
                margin-top: 4px
                display: flex
                flex-direction: row
                flex-wrap: wrap
                max-height: 60px
                overflow-x: hidden

        .content_bopas
            margin-top: 16px

        .content_Pagination
            margin-top: 24px
            display: flex
            flex-direction: row
            justify-content: center

        .content_mensaje
            margin-top: 24px

    .content_filtres_accordion
        margin-top: 24px

    .container_filtres
        .container_btn_filtres
            display: none

           


@media (max-width: variables.$breakpoint-tablet)
    .container
        .container_main
            margin-top: 24px



@media (max-width: variables.$breakpoint-phone)
    .container 
        .container_filtres  
            .container_btn_filtres
                display: none
