@use '../../../styles/variables.sass'

#accordionFilters
    .accordion-item.collapsed
        border:0px
    
    .accordion-item:not(.collapsed)
        border: 10px

    .accordion-button.collapsed
        border: none
        border: 0px !important
        font-weight: 600
        padding: 10px !important
        border-radius: 0px !important
        background-color: #FFF !important
        box-shadow: 0px 0px 0px 0px rgba(22, 160, 133, 0.5)

    .accordion-button:not(.collapsed)
        color: black
        border: 10px
        background-color: #FFF !important
        border-bottom: 1px solid variables.$gray
        font-weight: 600 
        padding: 10px
        border-radius: 0px !important
        box-shadow: 0px 0px 0px 0px rgba(22, 160, 133, 0.5)

    .accordion-button:not(.collapsed)::after
        background-image: var(--bs-accordion-btn-icon)

    label
        font-size: 0.8rem

    .accordion-body
        padding: 8px 8px  
    