@use '../../styles/variables.sass'
    
.container
    padding-top: 32px
    height: max-content

    .caption_regular
        margin-top: 16px

.buttonContainer
    display: flex

.container_btns
    display: flex
    flex-direction: row
    justify-content: space-between
    margin-top: 32px

@media (max-width: variables.$breakpoint-tablet)
   



@media (max-width: variables.$breakpoint-phone)