@use '../../styles/variables.sass'

.title
    padding: 16px
    font-style: normal
    font-weight: 600
    font-size: 36px
    line-height: 140%
    text-align: center
    color: #000000

.button
    text-align: -webkit-center
    padding-top: 16px

.subTitle
    font-style: normal
    font-weight: 400
    font-size: 16px
    line-height: 140%
    text-align: center
    color: #000000
    padding-bottom: 16px

.image
    padding: 16px
    text-align: center

.container
    padding-top: 100px