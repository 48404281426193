@use '../../../styles/variables.sass'
    
.react-calendar
    width: 100%
    border: none

    .abbr[title]
        text-decoration: none

.react-calendar__navigation
    display: none
.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button
    display: none

.react-calendar__tile
    overflow: inherit
    
.react-calendar__tile__BOPADay
    position: relative
    display: inline-block
    border-bottom: 1px dotted black
    background: variables.$primary_light
    border-radius: 2px
    overflow: inherit !important

.tooltiptext
    display:none
    visibility: hidden
    width: max-content 
    background-color: black
    color: #fff
    text-align: initial
    border-radius: 4px
    position: absolute
    box-shadow: 0px 4px 14px rgba(32, 31, 31, 0.1)
    z-index: 9999 !important

.tooltip__li
    padding: 5px 5px

.react-calendar__tile__BOPADay:hover .tooltiptext
    visibility: visible

.react-calendar__tile--now
    background-color: variables.$primary
    color: variables.$white

.react-calendar__tile--now :hover
    background-color: variables.$primary_dark

.react-calendar__tile--active
    background-color: white
    color: variables.$dark

.react-calendar__tile--active:focus
    background-color: white !important
    color: variables.$dark

.react-calendar__month-view__days__day--weekend
    color: variables.$primary

.react-calendar__tile--now :enabled :hover
.react-calendar__tile--now :enabled :focus
    background-color: variables.$primary

.react-calendar__tile--active
    background-color: white !important

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus
    background-color: variables.$primary-dark !important

abbr[title] 
    text-decoration: none !important
